<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div class="uk-form-stacked">
        <h4 class="uk-heading-line">
          Farmer Info
        </h4>
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Farmer Name
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPartner.name"
                    v-validate="'required|max:50'"
                    name="partner_name"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Farmer Name"
                    :class="{'uk-form-danger': errors.has('partner_name')}"
                  >
                </div>
                <span
                  v-show="errors.has('partner_name')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('partner_name') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Phone Number
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPartner.phone_number"
                    v-validate="'required|max:16'"
                    name="phone_number"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Phone Number"
                    onkeypress="return /^[0-9+]*$/i.test(event.key)"
                    :class="{'uk-form-danger': errors.has('phone_number')}"
                  >
                </div>
                <span
                  v-show="errors.has('phone_number')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('phone_number') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  NIK
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPartner.nik"
                    v-validate="'required|max:16'"
                    name="nik"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter NIK"
                    onkeypress="return /^[0-9+]*$/i.test(event.key)"
                    :class="{'uk-form-danger': errors.has('nik')}"
                  >
                </div>
                <span
                  v-show="errors.has('nik')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('nik') }}</span>
              </div>
            </div>
          </div>
          
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Join Date
                </label>
                <div class="uk-inline uk-width-1-1">
                  <img
                    :src="`${images}/icon/ic_calendar.svg`"
                    alt=""
                    class="uk-form-icon uk-form-icon-flip"
                  >
                  <datepicker
                    ref="join_date"
                    v-model="formPartner.join_date"
                    v-validate="'required'"
                    format="DD/MM/YYYY"
                    name="join_date"
                    input-class="uk-input"
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                <span
                  v-show="errors.has('join_date')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('join_date') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Email
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="formPartner.email"
                    v-validate="'required|email'"
                    name="email"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Email"
                    :class="{'uk-form-danger': errors.has('email')}"
                  >
                </div>
                <span
                  v-show="errors.has('email')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('email') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Status
                </label>
                <div class="uk-form-controls uk-margin-small-top">
                  <label class="switch">
                    <input
                      v-if="formPartner.status == 1"
                      class="switcher"
                      name="status"
                      type="checkbox"
                      checked
                      @change="handleStatus"
                    >
                    <input
                      v-else
                      class="switcher"
                      name="status"
                      type="checkbox"
                      @change="handleStatus"
                    >
                    <span class="slider round" />
                  </label>
                  <span :class="formPartner.status == 1 ? 'label-switcher active ' : 'label-switcher'">{{ formPartner.status == 1 ? status.ACTIVE : status.INACTIVE }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div
        class="uk-child-width-expand@s uk-grid-medium"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l">
          <h4 class="uk-heading-line">
            Address Info
          </h4>
          <div class="uk-form-stacked form-facility-fields">
            <div
              v-for="(data, index) in address_list"
              :key="index"
              class="uk-child-width-expand@s uk-grid-small" 
              uk-grid
            >
              <div class="uk-width-1-3">
                <div class="uk-form-controls uk-position-relative withlabel">
                  <label class="uk-form-label">
                    Address Type {{ index + 1 }}
                  </label>
                  <select
                    v-model="data.address_type"
                    class="uk-width-expand custom-select"
                    :name="`address_type_${index + 1}`" 
                  >
                    <option
                      value=""
                      hidden
                    >
                      Select Address Type
                    </option>
                    <option
                      v-for="(item, key) in listAddressType"
                      :key="key"
                      :value="item.name"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <img
                    :src="`${images}/icon/ic_chevron_down.svg`"
                    alt="ic_chevron"
                  >
                </div>
              </div>

              <div class="uk-width-1-3">
                <label class="uk-form-label">
                  Address {{ index + 1 }}
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="data.address"
                    :name="`address_${index + 1}`"
                    class="uk-input"
                    type="text"
                    autocomplete="off"
                    placeholder="Enter Address"
                    :class="{'uk-form-danger': errors.has(`address_${index + 1}`)}"
                  >
                </div>
              </div>

              <div class="uk-width-1-3">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div>
                    <label class="uk-form-label">
                      Latitude {{ index + 1 }}
                    </label>
                    <div class="uk-form-controls">
                      <input
                        v-model="data.latitude"
                        :name="`latitude_${index + 1}`"
                        class="uk-input"
                        type="text"
                        autocomplete="off"
                        placeholder="Enter Latitude"
                        :class="{'uk-form-danger': errors.has(`latitude_${index + 1}`)}"
                      >
                    </div>
                  </div>

                  <div>
                    <label class="uk-form-label">
                      Longitude {{ index + 1 }}
                    </label>
                    <div class="uk-form-controls">
                      <input
                        v-model="data.longitude"
                        :name="`longitude_${index + 1}`"
                        class="uk-input"
                        type="text"
                        autocomplete="off"
                        placeholder="Enter Longitude"
                        :class="{'uk-form-danger': errors.has(`longitude_${index + 1}`)}"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="img-fields"
                :hidden="index > 0 ? false : true"
              >
                <img
                  class="ic-delete-form"
                  :src="`${images}/icon/ic_delete_table.svg`"
                  alt=""
                  @click.prevent="handleDeleteFormAddress()"
                >
              </div>
            </div>
          </div>
      
          <div
            class="uk-child-width-expand@s uk-grid-small uk-margin-small-top"
            uk-grid
          >
            <button
              class="btn-add-create-form uk-button uk-flex uk-align-center uk-width-expand uk-width-auto@s"
              style="justify-content: flex-start;"
              @click.prevent="handleCreateFormAddress()"
            >
              <img
                :src="`${images}/icon/ic_plus_green.svg`"
                class="uk-margin-small-right cursor-pointer"
              >
              Add Address
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div
        class="uk-child-width-expand@s uk-grid-medium"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l">
          <h4 class="uk-heading-line">
            Facility
          </h4>
          <div class="uk-form-stacked form-facility-fields">
            <div
              v-for="(data, index) in facility_list"
              :key="index"
              class="uk-child-width-expand@s uk-grid-small" 
              uk-grid
            >
              <div class="uk-width-1-3">
                <div class="uk-form-controls uk-position-relative withlabel">
                  <label class="uk-form-label">
                    Facility Type {{ index + 1 }}
                  </label>
                  <select
                    class="uk-width-expand custom-select"
                    :name="`facility_type_${index + 1}`"
                    @change="e => handleSelectFacilityType(e, index)"
                  >
                    <option
                      value=""
                      hidden
                    >
                      Select Facility Type
                    </option>
                    <option
                      v-for="(item, idx) in listFacilityType"
                      :key="idx"
                      :value="idx"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <img
                    :src="`${images}/icon/ic_chevron_down.svg`"
                    alt="ic_chevron"
                  >
                </div>
              </div>

              <div class="uk-width-1-3">
                <div class="uk-form-controls uk-position-relative withlabel">
                  <label class="uk-form-label">
                    Facility Name {{ index + 1 }}
                  </label>
                  <select
                    class="uk-width-expand custom-select"
                    :class="data.facility_name_list.length > 0 ? '' : 'disabled'"
                    :name="`facility_name_${index + 1}`"
                    :disabled="!facility_type"
                    @change="e => handleSelectFacilityName(e, index, data.facility_name_list)"
                  >
                    <option
                      value=""
                      hidden
                    >
                      Select Facility Name
                    </option>
                    <option
                      v-for="(item, idx) in data.facility_name_list"
                      :key="idx"
                      :value="idx"
                    >
                      {{ item.facility_name }}
                    </option>
                  </select>
                  <img
                    :src="`${images}/icon/ic_chevron_down.svg`"
                    alt="ic_chevron"
                  >
                </div>
              </div>

              <div class="uk-width-1-3">
                <label class="uk-form-label">
                  Export Hub {{ index + 1 }}
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="data.export_hub"
                    :name="`export_hub_${index + 1}`"
                    class="uk-input readonly"
                    type="text"
                    autocomplete="off"
                    readonly
                  >
                </div>
              </div>

              <div
                class="img-fields"
                :hidden="index > 0 ? false : true"
              >
                <img
                  class="ic-delete-form"
                  :src="`${images}/icon/ic_delete_table.svg`"
                  alt=""
                  @click.prevent="handleDeleteFormFacility()"
                >
              </div>
            </div>
          </div>
      
          <div
            class="uk-child-width-expand@s uk-grid-small uk-margin-small-top"
            uk-grid
          >
            <button
              class="btn-add-create-form uk-button uk-flex uk-align-center uk-width-expand uk-width-auto@s"
              style="justify-content: flex-start;"
              @click.prevent="handleCreateFormFacility()"
            >
              <img
                :src="`${images}/icon/ic_plus_green.svg`"
                class="uk-margin-small-right cursor-pointer"
              >
              Add Facility
            </button>
          </div>
        </div>

        <div class="uk-flex uk-flex-right">
          <button
            class="uk-button soft-green uk-margin-right"
            @click="showCancelConfirmModal"
          >
            Cancel
          </button>
          <button
            class="uk-button green"
            :class="isCanAccessUser('add', 'FARMER') ? '' : 'disabled'"
            :disabled="!isCanAccessUser('add', 'FARMER')"
            @click="showSaveConfirmModal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { STATUS } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    Datepicker
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listFacilityType: {
      required: true,
      type: Array
    },
    listAddressType: {
      required: true,
      type: Array
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  
  data() {
    return{
      status: STATUS,
      formPartner: {
        name: '',
        phone_number: '',
        email: '',
        nik: '',
        join_date: new Date(),
        status: 1,
        addresses: [],
        facilities: []
      },
      facility_type: '',
      address_list: [
        {
          address_type: '',
          address: '',
          latitude: '',
          longitude: ''
        }
      ],
      facility_list: [
        {
          facility_id: '',
          facility_type_id: '',
          facility_type: '',
          export_hub: '',
          facility_name_list: []
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      actionGetListHulling: 'hullingFacility/getListHulling',
      actionGetListPulping: 'pulpingFacility/getListPulping',
      actionGetListPlantation: 'plantation/getListPlantation'
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    async handleSelectFacilityType(event, index){
      let idx = event.target.value

      this.facility_type = this.listFacilityType[idx].name

      this.facility_list[index].facility_type = this.facility_type
      this.facility_list[index].facility_type_id = this.listFacilityType[idx].id
      
      switch (this.facility_type) {
      case 'Hulling Facility':
        await this.actionGetListHulling({ limit: 1000, is_all: true }).then(result => {
          this.facility_list[index].facility_name_list = result
        })
        break
      case 'Pulping Facility':
        await this.actionGetListPulping({ limit: 1000, is_all: true }).then(result => {
          this.facility_list[index].facility_name_list = result
        })
        break
      case 'Plantation':
        await this.actionGetListPlantation({ limit: 1000, is_all: true }).then(result => {
          this.facility_list[index].facility_name_list = result
        })
        break
      default:
        break
      }
    },
    handleSelectFacilityName(event, mainIndex, data){
      let idx = event.target.value
      this.facility_list[mainIndex].facility_id = data[idx].id
      this.facility_list[mainIndex].export_hub = data[idx].export_hub?.name || '-'
    },
    handleCreateFormFacility() {
      this.facility_list.push({
        facility_id: '',
        facility_type_id: '',
        facility_type: '',
        export_hub: '',
        facility_name_list: []
      })
    },
    handleDeleteFormFacility() {
      this.facility_list.splice(this.facility_list.length - 1, 1)
    },
    handleCreateFormAddress() {
      this.address_list.push({
        address_type: '',
        address: '',
        latitude: '',
        longitude: ''
      })
    },
    handleDeleteFormAddress() {
      this.address_list.splice(this.address_list.length - 1, 1)
    },
    handleStatus(event) {
      this.formPartner.status = event.target.checked ? 1 : 0
    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'Partner',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {
      let tempAddress = []
      let tempFacility = []
      this.address_list.map(item => tempAddress.push({
        address_type: item.address_type,
        address: item.address,
        latitude: item.latitude,
        longitude: item.longitude
      }))
      this.facility_list.map(item => tempFacility.push({
        facility_id: item.facility_id,
        facility_type_id: item.facility_type_id,
        facility_type: item.facility_type
      }))
      this.formPartner.addresses = tempAddress
      this.formPartner.facilities = tempFacility

      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formPartner)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
   .custom-select.disabled{
    background: #DEDEDE;
  }
  .form-facility-fields .uk-width-1-3 {
    max-width: 32%;
  }
  .btn-add-create-form {
    font-family: 'interMedium';
    font-weight: 600;
    font-size: 16px;
    background: transparent;
    color: #2FAC4A;
  }
  .img-fields {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;
  }
  .ic-delete-form{
    width: 24px;
    height: 24px;
    max-width: 24px !important;
  }
  .uk-input:disabled{
    background: #F4F4F4 !important;
    color: #000000 !important;
  }
  .uk-form-icon {
    width: 24px;
    height: 24px;
    top: 25%;
    z-index: 1;
  }
  .uk-form-icon-flip {
    right: 10px;
    left: auto;
  }
</style>
